<template>
  <header class="container">
    <div class="header">
      <Logo />

      <div v-if="!($route.query.continue_url && $route.name === 'Funds')" class="header__right">
        <a v-if="showProVersion && isAuthenticated" :href="proVersionUrl" class="header__pro">PRO</a>
        <Balance class="header__balance" />
        <div @click="showMenu" class="header__menu-toggle">
          <img src="@/assets/icons/menu-alt.svg" alt="menu-alt">
          <div v-if="activeActivationsCount > 0" class="header__notifications">{{ activeActivationsCount }}</div>
        </div>
      </div>
    </div>
  </header>
  <transition name="fade">
    <SideNav v-show="isMenu" @hideMenu="hideMenu"/>
  </transition>
</template>

<script>
import { useActiveActivationsCount } from '@/use/activeActivationsCount'
import { useUser } from '@/use/user'
import Logo from './Logo.vue'
import Balance from './Balance.vue'
import SideNav from '@/components/SideNav/SideNav.vue'

export default {
  components: {
    Logo,
    Balance,
    SideNav
  },
  data () {
    return {
      isMenu: false,
      showProVersion: process.env.VUE_APP_SHOW_PRO_VERSION ? Number(process.env.VUE_APP_SHOW_PRO_VERSION) : 0,
      proVersionUrl: process.env.VUE_APP_PRO_VERSION_URL + '?project=act'
    }
  },
  computed: {
    activeActivationsCount () {
      return this.$store.state.activation.activeActivationsCount
    },
    isAuthenticated () {
      return this.$store.state.auth.isAuthenticated
    }
  },
  watch: {
    $route (to) {
      this.saveLastVisitPath(to.path)
    }
  },
  setup () {
    const { isUserEmailVerified } = useUser()
    const { getActiveActivationsCount } = useActiveActivationsCount()
    return { isUserEmailVerified, getActiveActivationsCount }
  },
  mounted () {
    this.saveLastVisitPath(this.$route.path)
    this.getActiveActivationsCount()
  },
  methods: {
    hideMenu () {
      this.isMenu = false
      document.querySelector('body').style.overflow = 'hidden'
    },
    showMenu () {
      this.isMenu = true
    },
    saveLastVisitPath (path) {
      if (this.isAuthenticated && !this.isUserEmailVerified()) {
        if (!['/', '/deeplink', '/change-email', '/change-password', '/create-account', '/signup', '/signin'].includes(path)) {
          localStorage.setItem('lastVisitedPath', path)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/animation/fade-solo.scss';

  .container {
    width: 100%;
    max-width: 1296px;
    position: relative;
    height: 100px;
  }

  .header {
    max-width: inherit;
    width: inherit;
    background: #FFF;
    z-index: 4;
    position: fixed;
    display: flex;
    justify-content: space-between;
    padding: 22px 0;

    &__balance{
      margin-right: 37px;
    }

    &__right {
      display: flex;
      margin-right: 50px;
    }

    &__pro {
      margin: 0 30px;
      background: #FA8231;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 0 25px;
      font-weight: 500;
      font-size: 16px;
      border-radius: 8px;
    }

    &__menu-toggle {
      position: relative;
      padding-top: 18px;
      // margin-left: 37px;
      cursor: pointer;
    }

    &__notifications {
      padding-top: 4px;
      text-align: center;
      position: absolute;
      top: 6px;
      right: -10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #FA8231;
      font-size: 10px;
      color: #fff;
    }
  }

  .rtl {
    .header {
      &__right{
        margin-right: 0;
        margin-left: 50px;
      }
      &__balance {
        margin-left: 37px;
        margin-right: 0;
      }
      &__menu-toggle {
        img {
          transform: rotate(180deg);
        }
      }
      &__notifications {
        left: -10px;
        right: auto;
      }
    }
  }

  @media (max-width: 590px) {
    .header {
      padding: 15px 0;

      &__menu-toggle {
        padding-top: 0;
        margin-left: 12px;
        margin-top: 13px;
        width: 24px;
        height: 16px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      &__balance {
        margin-right: 4px;
      }

      &__notifications {
        padding-top: 2px;
        height: 13px;
        width: 13px;
        font-size: 7px;
        top: -7px;
        right: -6px;
      }
    }

    .rtl{
      .header {
        &__menu-toggle {
          margin-right: 12px;
          margin-left: 0;
        }

        &__balance {
          margin-left: 4px;
          margin-right: 0;
        }

        &__notifications {
          left: -6px;
          right: auto;
        }
      }
    }
  }

  @media (min-width: 900px) and (max-height: 768px) {
    header.container {
      height: 86px;

      .header {
        padding: 15px 0;
      }
    }
  }

  @media (max-width: 320px) {
    header.container{
      height: 70px;
    }
    .header{
      &__menu-toggle {
        margin-top: 12px;
        height: 18px;
      }
    }
  }

  @media (max-width: 660px) {
    .header {
      &__pro {
        display: none;
      }
    }
  }
</style>
